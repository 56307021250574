import {
  Bar,
  BarChart as Chart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import CustomLegend from '@components/charts/Legend'
import CustomTooltip from '@components/charts/Tooltip'

import usePrintMode from '@hooks/usePrintMode'
import { BarChartProps, Data } from '../../hooks/useChartHooks'

const BarChart = (props: BarChartProps) => {
  const {
    chartOptions: {
      accentColor,
      barGap = 10,
      barSize,
      chartHeight,
      disableGrid,
      disableLegend,
      includeXAxis = true,
      layout,
      primaryColor,
    },
    dataPayload: { dataArray } = {},
    customClassName,
    height = 500,
    width = '100%',
  } = props

  const isPrinting = usePrintMode()

  return (
    <ResponsiveContainer width={width} height={height} className={customClassName}>
      <Chart
        barGap={barGap}
        data={dataArray}
        height={chartHeight}
        layout={layout}
      >
        {!disableGrid && (
          <CartesianGrid stroke="rgba(0, 0, 0, 0.1)" strokeDasharray="3 3" />
        )}

        <Bar
          barSize={barSize}
          dataKey="value"
          isAnimationActive={!isPrinting}
        >
          {(dataArray!).map((data: Data, index: number) => {
            const { main } = data
            const color = main ? primaryColor : accentColor
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Cell key={`cell-${index}`} fill={color} />
            )
          })}
        </Bar>

        {!includeXAxis
          && (
            <>
              <XAxis hide type={layout === 'vertical' ? 'number' : 'category'} />
              <YAxis hide dataKey="name" type={layout === 'vertical' ? 'category' : 'number'} />
            </>
          )}

        {includeXAxis && <XAxis axisLine={false} dataKey="name" fontSize={10} lineHeight={10} tickLine={false} /> }

        <YAxis dataKey="value" type={layout === 'vertical' ? 'category' : 'number'} />

        <Tooltip content={<CustomTooltip dataKeys={['value']} />} cursor={{ fill: 'none' }} />

        {!disableLegend && <Legend content={<CustomLegend />} verticalAlign="bottom" />}
      </Chart>
    </ResponsiveContainer>
  )
}

export default BarChart
