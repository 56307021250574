import { useEffect } from 'react'

import { generateUserRoleString } from '@functions/user'

import useBrandSelector from './useBrandSelector'
import useCurrentUser from './useCurrentUser'
import useOrganizationSelector from './useOrganizationSelector'

type IntercomType = {
  hideWidget?: boolean,
  horizontalPadding?: number,
}

export type TrackEventParams = {
  eventName: string,
  eventPayload?: Record<string, string | boolean>,
}

// eslint-disable-next-line consistent-return
const useIntercom = (options: IntercomType = {}) => {
  const { hideWidget = false, horizontalPadding = 20 } = options

  const currentUserPayload = useCurrentUser()
  const { currentUser, isBrandUser } = currentUserPayload

  const organizationSelectorPayload = useOrganizationSelector()
  const { selectedOrganization } = organizationSelectorPayload

  const brandSelectorPayload = useBrandSelector({ autoSelect: true })
  const { selectedBrand } = brandSelectorPayload

  const {
    email, full_name, id, mobile, realbase_id,
  } = currentUser

  if (hideWidget) return window.Intercom('shutdown')

  useEffect(() => {
    if (id){
      const { Intercom } = window

      Intercom('update', {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        brand: selectedBrand?.title,
        business_unit: 'Engage',
        email,
        horizontal_padding: horizontalPadding,
        name: full_name,
        onboarding: selectedOrganization?.onboarding || false,
        organization: selectedOrganization?.title,
        phone: mobile || 'none',
        realbase_id: `Realbase ID #${realbase_id}`,
        role: isBrandUser ? 'Brand User' : generateUserRoleString(currentUser, selectedOrganization?.id),
        user_id: id,
        window_location: window.location.href,
      })
    }
  }, [id, JSON.stringify(options)])

  const intercomElement = document.querySelector('.intercom-launcher')

  useEffect(() => {
    // Add event listener to close Intercom widget on click outside
    const handleClickOutside = (event) => {
      if (intercomElement && !intercomElement.contains(event.target)){
        window.Intercom('hide')
      }
    }

    document.addEventListener('click', handleClickOutside)

    // Clean up function to remove event listener when unmounting
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [intercomElement])

  const trackEvent = (params: TrackEventParams) => {
    const { eventName, eventPayload = {} } = params
    if (window.Intercom){
      const extendedPayload = {
        ...eventPayload,
        business_unit: 'Engage',
        onboarding: selectedOrganization?.onboarding || false,
        organization: selectedOrganization?.title || selectedOrganization?.id,
        realbase_id: `Realbase ID #${realbase_id}`,
        role: generateUserRoleString(currentUser, selectedOrganization?.id),
        user_id: id || 'Unknown User',
      }
      window.Intercom('trackEvent', eventName, extendedPayload)
    }
  }

  return {
    callbacks: {
      trackEvent: (params: TrackEventParams) => {
        trackEvent(params)
      },
    },
  }
}

export default useIntercom
