import React, { useState, useEffect } from 'react'

export type Data = {
  main: boolean,
  name: string,
  value: number | string,
}

type ChartOptions = {
  accentColor: string,
  barGap?: string | number,
  barSize?: number,
  chartHeight?: number,
  disableGrid?: boolean,
  disableLegend?:boolean,
  includeXAxis?: boolean,
  innerRadius?: number,
  layout?: 'horizontal' | 'vertical',
  outerRadius?: number,
  primaryColor: string,
}

type DataPayload = {
  dataArray: Data[],
  dataKeys: string[],
}

export type PieChartProps = {
  chartOptions: ChartOptions,
  customClassName?: string,
  dataPayload: DataPayload,
  height?: number,
  width?: number,
}

export type BarChartProps = {
  chartOptions: ChartOptions,
  customClassName?: string,
  dataPayload: DataPayload,
  height?: number,
  width?: number,
}

export type LineChartProps = {
  chartOptions: ChartOptions,
  customClassName?: string,
  dataPayload: DataPayload,
  height?: number,
  width?: number,
}

export type AreaChartProps = {
  chartOptions: ChartOptions,
  customClassName?: string,
  dataPayload: DataPayload,
  height?: number,
  width?: number,
}

export interface ActiveShapeProps {
  currentProvider?: Data,
  currentProviderPercentage?: number,
  cx?: number,
  cy?: number,
  dataArray?: Data[],
  endAngle?: number,
  fill?: string,
  innerRadius?: number,
  midAngle?: number,
  outerRadius?: number,
  payload?: Data,
  percent?: number,
  startAngle?: number,
  value?: number,
  chartOptions: ChartOptions,
}

interface UseActiveIndexReturn {
  activeIndex: number | null,
  setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>,
}

export function useActiveIndex(dataArray: Data[]): UseActiveIndexReturn {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  useEffect(() => {
    const mainIndex = dataArray.findIndex(entry => entry.main)
    setActiveIndex(mainIndex >= 0 ? mainIndex : null)
  }, [dataArray])

  return { activeIndex, setActiveIndex }
}

export function useCurrentProvider(dataArray: Data[]):
  { currentProvider: Data | undefined, currentProviderPercentage: number } {
  if (dataArray.length === 0){
    return { currentProvider: undefined, currentProviderPercentage: 0 }
  }
  const currentProvider = dataArray[0]
  const totalValue = dataArray.reduce((acc, curr) => acc + Number(curr.value), 0)
  const currentProviderPercentage = totalValue ? (Number(currentProvider.value) / totalValue) * 100 : 0

  return { currentProvider, currentProviderPercentage }
}
