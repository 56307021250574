import React from 'react'
import PropTypes from 'prop-types'

import { Box, DashboardModule, FormField } from '@campaignhub/suit-theme'

import countryCallingCodes from '@functions/countryCallingCodes'

import useCurrentUser from '@hooks/useCurrentUser'

import EntitySelectBox from '@components/EntitySelectBox'
import FieldHolder from '@components/FieldHolder'

const OrganizationDetails = (props) => {
  const { organizationForm } = props

  const {
    entityState: {
      brand_id, country_calling_code, email, onboarding, organization_type_id, phone, realbase_id, realbase_type, title, url,
    },
    errors,
    handlers,
  } = organizationForm

  const { isAdmin, isMasterAdmin } = useCurrentUser()

  return (
    <DashboardModule boxProps={{ marginBottom: 'large' }} title="Organisation Details">
      <Box flexDirection="column" padding="large">
        <FormField boxProps={{ marginBottom: 'large' }} errorMessage={errors.brand_id} label="* Brand">
          <EntitySelectBox
            entityKey="brands"
            name="brand_id"
            sortDirection="asc"
            sortKey="title"
            value={brand_id}
            {...handlers}
          />
        </FormField>
        <Box flexDirection={['column', 'row']} marginBottom="large">
          <FormField
            boxProps={{ marginBottom: 'large' }}
            errorMessage={errors.organization_type_id}
            label="* Organization Type"
            boxProps={{ flex: 1, marginBottom: ['large', 0] }}
          >
            <EntitySelectBox
              entityKey="organizationTypes"
              name="organization_type_id"
              sortDirection="asc"
              sortKey="title"
              value={organization_type_id}
              {...handlers}
            />
          </FormField>

          {isMasterAdmin && (
            <FormField
              boxProps={{ marginBottom: 'large' }}
              errorMessage={errors.onboarding}
              label="Onboarding"
              boxProps={{ flex: 1, marginBottom: ['large', 0], marginLeft: [0, 'medium'] }}
            >
              <select
                data-validate-field-on="change"
                name="onboarding"
                value={onboarding}
                {...handlers}
              >
                <option value="">Please Select ...</option>
                <option value="false">No</option>
                <option value="true">Yes</option>
              </select>
            </FormField>
          )}
        </Box>

        <FieldHolder boxProps={{ marginBottom: 'large' }} label="* Organisation Name" errorMessage={errors.title}>
          <input type="text" name="title" value={title} {...handlers} />
        </FieldHolder>

        <Box flexDirection={['column', 'row']} marginBottom="large">
          <FormField
            label="* Email"
            errorMessage={errors.email}
            boxProps={{ flex: 1, marginBottom: ['large', 0], marginRight: [0, 'medium'] }}
          >
            <input type="text" value={email} name="email" {...handlers} />
          </FormField>

          <FormField label="URL" boxProps={{ flex: 1 }}>
            <input type="text" value={url} name="url" {...handlers} />
          </FormField>
        </Box>

        <Box flexDirection={['column', 'row']} marginBottom="large">
          <FormField
            label="Realhub Type"
            errorMessage={errors.realbase_type}
            boxProps={{ flex: 1, marginBottom: ['large', 0], marginRight: [0, 'medium'] }}
          >
            <select
              data-validate-field-on="change"
              disabled={!isAdmin}
              name="realbase_type"
              value={realbase_type}
              {...handlers}
            >
              <option value="">Please Select...</option>
              <option key="agency" value="RealbaseResource::Agency">
                Agency
              </option>
            </select>
          </FormField>

          <FormField label="Realhub ID" boxProps={{ flex: 1 }}>
            <input
              disabled={!isAdmin}
              name="realbase_id"
              type="text"
              value={realbase_id}
              {...handlers}
            />
          </FormField>
        </Box>

        <Box flexDirection={['column', 'row']}>
          <Box width={['100%', 'calc(50% - 4px)']}>
            <FormField label="Phone" boxProps={{ flex: 1 }}>
              <Box style={{ width: '100%', marginRight: 'small', flexDirection: 'row' }}>
                <select
                  name="country_calling_code"
                  style={{ marginRight: 5 }}
                  value={country_calling_code}
                  {...handlers}
                >
                  <option value="">Select...</option>

                  {countryCallingCodes.map(({ key, value, title }) => (
                    <option key={key} value={value}>
                      {title}
                    </option>
                  ))}
                </select>

                <FormField style={{ width: '70%' }}>
                  <input type="text" value={phone} name="phone" {...handlers} />
                </FormField>
              </Box>
            </FormField>
          </Box>
        </Box>
      </Box>
    </DashboardModule>
  )
}

OrganizationDetails.propTypes = {
  organizationForm: PropTypes.object.isRequired,
}

export default OrganizationDetails
