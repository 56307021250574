import { useState } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-light-svg-icons'

import {
  Box, FormField, LoadingModule, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import PropertySuggestionWithButton from '../PropertySuggestionWithButton'
import localizedStrings from './localizedStrings'

const handleKeyUp = (e, combinedSearch, selectedIntegrationPlatformKey) => {
  if (e.keyCode === 13 && selectedIntegrationPlatformKey && e.target.value){
    combinedSearch(selectedIntegrationPlatformKey, e.target.value)
  }
}

const SearchComparables = (props) => {
  const { filteredComparables, useFindComparablesPayload } = props
  const [selectedIntegrationMenuOption, setSelectedIntegrationMenuOption] = useState('pricefinder')
  const {
    callbacks: {
      combinedSearch, createComparableFromProject, importComparable, setModalState,
    },
    filteredPlatforms,
    hasMarketResults,
    hasOrganizationResults,
    marketResults,
    organizationResults,
    resultsCount,
    searchComplete,
    searchString,
    searchedString,
    searching,
    selectedIntegrationPlatformKey,
    useAuthorizeIntegrationPayload,
  } = useFindComparablesPayload

  const {
    integrationExpired,
  } = useAuthorizeIntegrationPayload || {}

  // This function has been created to handle the selection of the integration platform.
  // This is a temporary solution to ensure that the exact search option uses Pricefinder instead of Domain Group.
  // This is as a result of a bug that is brought on by the response from the Domain Group API.
  // This is related to bug/ENG-134 and should be removed once the Domain Group integration is discontinued.
  // TODO: Remove this function once the Domain Group integration is discontinued.
  const handleIntegrationPlatformSelection = ({ target: { value } }) => {
    const selectedIntegrationPlatformKeyModifier = value === 'domain_group' ? 'pricefinder' : value
    setSelectedIntegrationMenuOption(value)
    setModalState({ selectedIntegrationPlatformKey: selectedIntegrationPlatformKeyModifier })
  }

  const {
    callbacks: { formatCount },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="column" flexShrink={0}>
      <FormField direction="column" label={strings.labels?.platform || 'Platform'}>
        <select
          data-validate-field-on="change"
          onChange={handleIntegrationPlatformSelection}
          value={selectedIntegrationMenuOption}
        >
          <option value="">{strings.options?.pleaseSelect || 'Please select...'}</option>
          {filteredPlatforms.map(entity => (
            <option key={entity.id} value={entity.key}>
              {entity.title}
            </option>
          ))}
        </select>
      </FormField>

      {!integrationExpired && (
      <FormField label={strings.propertySearch || 'Exact Match'} marginTop="large">
        <Box
          alignItems="center"
          border="1px solid"
          borderColor="lineColor"
          borderRadius="5px"
          fontSize="medium"
          height={38}
          paddingLeft="large"
          position="relative"
        >
          <FontAwesomeIcon icon={faSearch} />
          <input
            onChange={e => setModalState({ searchString: e.target.value })}
            onKeyUp={e => handleKeyUp(e, combinedSearch, selectedIntegrationPlatformKey)}
            placeholder={strings.addressSearch || 'Search for an exact match property'}
            style={{ border: 'none', height: '24px' }}
            type="text"
            value={searchString}
          />
        </Box>
      </FormField>
      )}

      <LoadingModule boxProps={{ marginTop: 'large' }} loading={searching} />

      {searchComplete && (
        <>
          <Box flexDirection="column" fontSize="xsmall" marginTop="large">
            <Text color="bodyFontLightColor">{formatCount('results', { count: resultsCount })}</Text>

            <Text marginTop="small">{searchedString}</Text>
          </Box>

          <FormField label={strings.marketProperties || 'Market Data Properties'} marginTop="large">
            {!hasMarketResults && (
              <Text color="faintGrey" fontSize="small" marginTop="medium">
                {strings.noMarketProperties || 'No Market Data Property Results.'}
              </Text>
            )}

            {hasMarketResults
              && marketResults.map(result => (
                <PropertySuggestionWithButton
                  added={filteredComparables.some(
                    comparable => comparable.data
                      && comparable.data.source_platform === result.service_provider
                      && comparable.data.external_ids[comparable.data.source_platform] === result.id,
                  )}
                  comparable={result}
                  key={result.id}
                  onClickFn={importComparable}
                />
              ))}
          </FormField>

          <FormField label={strings.agencyProperties || 'Your Agency Properties'} marginTop="large">
            {!hasOrganizationResults && (
              <Text color="faintGrey" fontSize="small" marginBottom="large" marginTop="medium">
                {strings.noAgencyProperties || 'No Agency Property Results.'}
              </Text>
            )}

            {organizationResults.map(result => (
              <PropertySuggestionWithButton
                added={filteredComparables.some(
                  comparable => comparable.data
                    && comparable.data.source_platform === 'engage'
                    && comparable.data.external_ids.engage === result.id,
                )}
                comparable={result}
                key={result.id}
                onClickFn={createComparableFromProject}
              />
            ))}
          </FormField>
        </>
      )}
    </Box>
  )
}

SearchComparables.propTypes = {
  filteredComparables: PropTypes.array.isRequired,
  useFindComparablesPayload: PropTypes.object.isRequired,
}

export default SearchComparables
