import { useEffect, useState } from 'react'

const usePrintMode = () => {
  const [printMode, setPrintMode] = useState(false)

  useEffect(() => {
    const mediaQueryList = window.matchMedia('print')
    const listener = (event: MediaQueryListEvent) => {
      setPrintMode(event.matches)
    }

    mediaQueryList.addEventListener('change', listener)

    return () => {
      mediaQueryList.removeEventListener('change', listener)
    }
  }, [])

  return printMode
}

export default usePrintMode
