const comparablesTooltipContent = {
  title: 'Pick the right method to add a comparable',
  items: [
    {
      subtitle: 'Browse Comparables',
      text: `Utilise your integrated data platform to explore and identify
      comparables by applying property characteristic filters.`,
    },
    {
      subtitle: 'Search Exact Match',
      text: 'Already know the address of the comparable? Use this option to directly select the property.',
    },
    {
      subtitle: 'Manually Add Comparable',
      text: `If the comparable is not available through the data platforms,
      Engage lets you manually enter its details.`,
    },
    {
      subtitle: 'Use Existing Comparables',
      text: 'For localised projects, leverage comparables from a similar project.',
    },
  ],
}

export default comparablesTooltipContent
