import React from 'react'
import PropTypes from 'prop-types'

import { Box, DashboardModule, FormField } from '@campaignhub/suit-theme'

import useOrganizationTypes from '@hooks/useOrganizationTypes'

const BrandDetails = (props) => {
  const { brandForm } = props

  const {
    entityState: {
      organization_type_id, realbase_type, realbase_id, title,
    },
    errors,
    handlers,
  } = brandForm

  const { organizationTypes } = useOrganizationTypes({ performHttpRequests: true })

  return (
    <DashboardModule boxProps={{ marginBottom: 'large' }} title="Brand Details">
      <Box flexDirection="column" padding="large">
        <FormField boxProps={{ marginBottom: 'large' }} label="* Brand Title" errorMessage={errors.title}>
          <input type="text" name="title" value={title} {...handlers} />
        </FormField>

        <Box flexDirection={['column', 'row']}>
          <FormField
            label="Realhub Type"
            errorMessage={errors.realbase_type}
            boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
          >
            <select name="realbase_type" value={realbase_type} {...handlers} data-validate-field-on="change">
              <option value="">Please Select...</option>
              <option key="brand_type" value="RealbaseResource::Brand">Brand</option>
            </select>
          </FormField>

          <FormField label="Realhub ID" boxProps={{ flex: 1 }}>
            <input type="text" value={realbase_id} name="realbase_id" {...handlers} />
          </FormField>
        </Box>

        <FormField boxProps={{ marginTop: 'large' }} label="* Organization Type" errorMessage={errors.title}>
          <select name="organization_type_id" value={organization_type_id} {...handlers} data-validate-field-on="change">
            <option value="">Please Select...</option>
            {
              Object.values(organizationTypes)
                .map(organizationType => <option key={organizationType.id} value={organizationType.id}>{organizationType.title}</option>)
            }
          </select>
        </FormField>
      </Box>
    </DashboardModule>
  )
}

BrandDetails.propTypes = {
  brandForm: PropTypes.object.isRequired,
}

export default BrandDetails
