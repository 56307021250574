import {
  CartesianGrid,
  Legend,
  Line,
  LineChart as Chart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import CustomLegend from '@components/charts/Legend'
import CustomTooltip from '@components/charts/Tooltip'

import usePrintMode from '@hooks/usePrintMode'
import CustomLabel from './components/CustomLabel'
import CustomYAxis from './components/CustomYAxis'

import { LineChartProps } from '../../hooks/useChartHooks'

const LineChart = (props: LineChartProps) => {
  const {
    chartOptions: {
      barGap = 10,
      chartHeight,
      disableGrid,
      disableLegend,
      includeXAxis = true,
      layout,
      primaryColor,
    },
    dataPayload: { dataArray } = {},
    customClassName,
    height = 400,
    width = '100%',
  } = props

  const isPrinting = usePrintMode()

  return (
    <ResponsiveContainer width={width} height={height} className={customClassName}>
      <Chart
        barGap={barGap}
        data={dataArray}
        height={chartHeight}
        layout={layout}
      >
        {!disableGrid && (
          <CartesianGrid stroke="rgba(0, 0, 0, 0.1)" strokeDasharray="3 3" />
        )}

        <Line
          type="monotone"
          label={<CustomLabel />}
          dataKey="value"
          stroke={primaryColor}
          activeDot={{ r: 8 }}
          strokeWidth={2}
          isAnimationActive={!isPrinting}
        />

        {!includeXAxis
          && (
            <>
              <XAxis hide type={layout === 'vertical' ? 'number' : 'category'} />
              <YAxis hide dataKey="name" type={layout === 'vertical' ? 'category' : 'number'} />
            </>
          )}

        {includeXAxis && (
          <XAxis
            axisLine
            dataKey="name"
            fontSize={10}
            lineHeight={10}
            padding={{ left: 60, right: 60 }}
            tick={<CustomYAxis />}
            tickLine={false}
          />
        ) }

        <YAxis
          dataKey="value"
          type={layout === 'vertical' ? 'category' : 'number'}
        />

        <Tooltip content={<CustomTooltip dataKeys={['value']} />} cursor={{ fill: 'none' }} />

        {!disableLegend && <Legend content={<CustomLegend />} verticalAlign="bottom" />}
      </Chart>
    </ResponsiveContainer>
  )
}

export default LineChart
