import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Heading, HoverHintWrapper, Text,
} from '@campaignhub/suit-theme'

import styles from './assets/styles.module.scss'

type TooltipContentItem = {
  subtitle?: string | null,
  text?: string,
}

type CustomTooltipProps = {
  items: TooltipContentItem[],
  title?: string | null,
}

type SidebarCustomHeaderProps = {
  callbacks?: {
    closeModal: () => void,
  },
  title?: string,
  titleSecondLine?: string,
  tooltipContent?: CustomTooltipProps,
}

const SidebarCustomHeaderWithTooltip: React.FC<SidebarCustomHeaderProps> = ({
  callbacks = {},
  titleSecondLine,
  title,
  tooltipContent,
}) => {
  const { closeModal } = callbacks

  return (
    <Box
      background="white"
      borderBottom="1px solid"
      borderColor="lineColor"
      height="128px"
      justifyContent="space-between"
      paddingX="large"
      paddingY="xlarge"
      position="relative"
    >
      <Box flexDirection="column" lineHeight="1.3">
        <Heading size="large">{title || 'Heading'}</Heading>

        {titleSecondLine && <Heading size="large">{titleSecondLine}</Heading>}
      </Box>

      {tooltipContent && (
        <HoverHintWrapper
          boxProps={{
            alignItems: 'start',
            height: '100%',
            padding: '16px',
            width: '15%',
          }}
          hintComponent={(
            <HoverHintWrapper.Hint
              boxProps={{
                backgroundColor: '#373d3f',
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'column',
                fontSize: 'small',
                padding: 'large',
                right: '-25px',
                width: '300px',
              }}
            >
              <span className={styles.tooltipTriangle} />

              {tooltipContent.title && (
                <Text
                  color="white"
                  fontWeight="500"
                  lineHeight="1.43"
                  style={{
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                  }}
                >
                  {tooltipContent.title}
                </Text>
              )}

              {tooltipContent.items?.map(item => (
                <Box
                  flexDirection="column"
                  key={item.text}
                  marginBottom="medium"
                  padding="8px 0"
                  style={{
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                  }}
                >
                  {item.subtitle && (
                    <Text color="faintGrey" lineHeight="1.43" paddingBottom="8px">
                      {item.subtitle}
                    </Text>
                  )}

                  {item.text && (
                    <Text color="mysteryGrey" lineHeight="1.43" maxWidth="100%">
                      {item.text}
                    </Text>
                  )}
                </Box>
              ))}
            </HoverHintWrapper.Hint>
          )}
        >
          <FontAwesomeIcon
            icon={faCircleInfo}
            style={{
              color: '#D3D3D3',
              cursor: 'pointer',
              fontSize: '18px',
            }}
          />
        </HoverHintWrapper>
      )}

      <Text
        fontSize={['xxlarge', 'xlarge']}
        onClick={closeModal}
        padding="large"
        style={{ cursor: 'pointer' }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </Text>
    </Box>
  )
}

export default SidebarCustomHeaderWithTooltip
