import { useEffect, useState } from 'react'
import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'

import { digObject } from '@campaignhub/javascript-utils'

import { useThunkDispatch } from '@campaignhub/react-hooks'

import api from '@functions/api'

import * as attachmentActions from '@redux/modules/attachment'

import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'

const initializeUppy = (options, dispatch) => {
  const {
    afterCreateCallback, attachmentParams, maxNumberOfFiles, requestOptions, uppyOptions = {},
  } = options

  const { allowedFileTypes } = uppyOptions
  const { createAttachment } = attachmentActions

  const uppy = new Uppy({
    autoProceed: true,
    debug: false,
    restrictions: {
      maxNumberOfFiles: maxNumberOfFiles || 5,
      allowedFileTypes: allowedFileTypes ? allowedFileTypes.split(',') : ['.doc', '.docx', '.pdf', '.json'],
    },
    ...uppyOptions,
  })
    .use(AwsS3, {
      getUploadParameters(file){
        const uploadRequestOptions = {
          content_type: file.type,
          file_name: file.name,
        }

        const config = {
          method: 'GET',
        }

        // Send off request to API -> Shrine -> S3 to give us back upload params
        return api('attachments/presign', uploadRequestOptions, config)
          .then(response => response?.data)
      },
    })
    .on('upload-success', (file) => {
      const attachment = {
        ...attachmentParams,
        file: {
          id: file.meta.key.match(/cache\/\w+\/(.+)/)[1], // object key without prefix,
          storage: 'cache',
          metadata: {
            size: file.size,
            filename: file.name,
            mime_type: file.type,
          },
        },
        file_name: file.name,
        file_size: file.size,
        file_type: file.type,
        title: file.name,
      }

      dispatch(createAttachment(attachment, requestOptions)).then((response) => {
        if (afterCreateCallback){
          afterCreateCallback(response, uppy, file)
        }
      })
    })

  return uppy
}

function useUppyAttachmentUpload(options, deps = []){
  const [uppy, setUppy] = useState(undefined)

  const dispatch = useThunkDispatch()

  useEffect(() => {
    if (digObject(options, 'attachmentParams.subject_id')){
      return setUppy(initializeUppy(options, dispatch))
    }

    return () => {
      if (uppy){
        uppy.close()
        setUppy(undefined)
      }
    }
  }, [...deps])

  return uppy
}

export default useUppyAttachmentUpload
