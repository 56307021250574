import { faClockRotateLeft, faComments } from '@fortawesome/pro-light-svg-icons'

import { Box, SegmentedControl } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from '../localizedStrings'

interface BrowseComparablesFiltersProps {
  filterComparablesBySuburbKey: string,
  setFilterComparablesBySuburbKey: (value: string) => void,
}

const BrowseComparablesFilters = (props: BrowseComparablesFiltersProps) => {
  const { filterComparablesBySuburbKey, setFilterComparablesBySuburbKey } = props

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box style={{ whiteSpace: 'nowrap' }}>
      <SegmentedControl
        activeButtonKey={filterComparablesBySuburbKey}
        onClick={key => setFilterComparablesBySuburbKey(key)}
        boxProps={{ marginTop: 'large' }}
        buttons={[
          { key: 'this_suburb', icon: faClockRotateLeft, label: strings.thisSuburb || 'This Suburb' },
          { key: 'adjoining_suburb', icon: faComments, label: strings.adjoiningSuburbs || 'Adjoining Suburbs' },
        ]}
      />
    </Box>
  )
}

export default BrowseComparablesFilters
