import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@campaignhub/suit-theme'

import useOAuthIntegration from '@hooks/useOAuthIntegration'

/* Usage

<ButtonWithOauth
  authProviderKey="core_logic"
  buttonStyle="secondaryCreate"
  queryParams={{
    organization_id: selectedOrganization.id,
    redirect: window.location.href,
  }}
  size="medium"
  width="auto"
>
  Connect
</ButtonWithOauth>

*/

const ButtonWithOauth = (props) => {
  const {
    authProviderKey, queryParams, onClick: onClickTrackingProp, ...buttonProps
  } = props

  const authPayload = useOAuthIntegration(authProviderKey, { queryParams })
  const {
    callbacks: { onClick }, csrfToken, href, formRef,
  } = authPayload

  const handleButtonClick = (event) => {
    if (onClickTrackingProp){
      onClickTrackingProp(event)
    }

    onClick(event)
  }

  return (
    <>
      <form ref={formRef} action={href} method="post">
        <input type="hidden" name="authenticity_token" value={csrfToken} />
      </form>

      <Button onClick={handleButtonClick} {...buttonProps} />
    </>
  )
}

ButtonWithOauth.propTypes = {
  authProviderKey: PropTypes.string,
  queryParams: PropTypes.object,
  onClick: PropTypes.func,
}

export default ButtonWithOauth
