const defaultRequestOptions = {
  agreement: {
    include_agreement_recipients: true,
    include_agreement_urls: true,
  },
  attachment: {
    include_attachment_file: true,
  },
  digitalTemplate: {
    include_digital_template_permission_ids: true,
    include_digital_template_preview_image: true,
    status: 'current',
  },
  documentTemplate: {
    include_document_template_default_image: true,
  },
  eventCalendar: {
    include_event_calendar_event_groups: true,
    include_event_calendar_events: true,
  },
  eventCalendarTemplate: {
    include_event_calendar_event_count: true,
    include_event_calendar_event_group_count: true,
  },
  image: {
    include_image_sizes: ['thumb_90x90', 'thumb_660x360'],
  },
  integration: {
    include_system_integrations: true,
  },
  integrationPlatform: {
    include_hidden: true,
  },
  organization: {
    include_organization_addresses: true,
    include_organization_data_store_items: true,
    include_organization_default_address: true,
    include_organization_default_image: true,
    include_organization_image_sizes: ['thumb_90x90'],
    include_organization_sorted_user_ids: true,
    include_organization_type: true,
    include_organization_users_where_admin_ids: true,
    include_organization_users_where_owner_ids: true,
    include_organizations_user_ids: true,
  },
  organizationType: {
    include_organization_type_default_project_type_id: true,
  },
  review: {
    include_review_address: true,
    include_review_user_ids: true,
  },
  quote: {
    include_quote_sections: true,
    include_quote_items: true,
  },
  user: {
    include_user_brand_ids: true,
    include_user_brands_where_admin_ids: true,
    include_user_data_store_items: true,
    include_user_default_image: true,
    include_user_organization_ids: true,
    include_user_organizations_users: true,
    include_user_organizations_where_admin_ids: true,
    include_user_organizations_where_owner_ids: true,
  },
}

export default defaultRequestOptions
