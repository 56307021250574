import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'
import {
  Box, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import useIntegration, { useIntegrationForm } from '@hooks/useIntegration'
import useIntegrationPlatform from '@hooks/useIntegrationPlatform'
import useLocalization from '@hooks/useLocalization'

import ButtonWithOauth from '@components/ButtonWithOauth'
import FilterUsersContactsAndTeams from '@components/FilterUsersContactsAndTeams'
import ModalIntegrationPlatform from '@components/ModalComponents/ModalIntegrationPlatform'
import ModalUsers from '@components/ModalComponents/ModalUsers'

import useCurrentUser from '@hooks/useCurrentUser'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import Disclaimer from './components/Disclaimer'
import localizedStrings from './localizedStrings'

import useIntegrationAuthorization from './hooks/useIntegrationAuthorization'

const AuthorizeIntegrationModal = (props) => {
  const {
    callbacks, modalKey, showModal, trackEvent,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const { integration = {}, integrationPlatform } = modalPayload

  const { isSelectedOrganizationAdmin } = useOrganizationSelector()
  const { currentUser } = useCurrentUser()

  const integrationPlatformPayload = useIntegrationPlatform(integrationPlatform)
  const {
    hasDisclaimer, integrationPlatform: { key }, integrationScope, oauthProviderKey,
  } = integrationPlatformPayload

  const integrationFormPayload = useIntegrationForm(integration)
  const {
    callbacks: { trackIntegrationEvent },
  } = useIntegration(integration)
  const {
    entityState: { integration_scope, subject_id, subject_type },
    setEntityState,
  } = integrationFormPayload

  const integrationAuthorizationPayload = useIntegrationAuthorization()
  const { callbacks: { toggleDisclaimerAcceptance }, disclaimerAccepted } = integrationAuthorizationPayload

  useEffect(() => {
    if (!isSelectedOrganizationAdmin && !!currentUser.id){
      setEntityState({ subject_id: currentUser.id, subject_type: 'User' })
    }
  }, [isSelectedOrganizationAdmin, currentUser.id])

  const saveDisabled = integration_scope === 'user'
    ? (hasDisclaimer && !disclaimerAccepted) || !subject_id
    : hasDisclaimer && !disclaimerAccepted

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.title || 'Authorize'}
        titleSecondLine={strings.titleSecondLine || 'Integration'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <ModalIntegrationPlatform integrationPlatform={integrationPlatform} />

          {integrationScope === 'user' && (
          <>
            {isSelectedOrganizationAdmin && (
            <FormField direction="column" label={`* ${strings.labelUser || 'User'}`} marginTop="large">
              <FilterUsersContactsAndTeams
                callbacks={{
                  selectItem: selected => setEntityState({ subject_id: selected.id, subject_type: 'User' }),
                }}
                selectedUserIds={[subject_id]}
              />
            </FormField>
            )}

            <ModalUsers
              callbacks={{ deleteUser: () => setEntityState({ subject_id: null, subject_type: '' }) }}
              disableDeleteForUserIds={isSelectedOrganizationAdmin ? [] : [currentUser.id]}
              userIds={[subject_id]}
            />
          </>
          )}

          {hasDisclaimer && (
            <Disclaimer
              accepted={disclaimerAccepted}
              callbacks={{ toggleDisclaimerAcceptance }}
              integrationPlatformKey="core_logic_au"
            />
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <ButtonWithOauth
          authProviderKey={oauthProviderKey}
          buttonStyle="primaryCreate"
          disabled={saveDisabled}
          onClick={
            key === 'real_time_agent'
              ? () => trackIntegrationEvent({ action: 'authorizeEvent', integrationPlatformKey: key, trackEvent })
              : null
          }
          queryParams={{
            integration_platform_key: key,
            owner_id: integration.owner_id,
            owner_type: integration.owner_type,
            redirect: window.location.href,
            subject_id,
            subject_type,
          }}
          size="large"
        >
          {strings.button || 'Authorize Integration'}
        </ButtonWithOauth>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

AuthorizeIntegrationModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
  trackEvent: PropTypes.string.isRequired,
}

AuthorizeIntegrationModal.defaultProps = {
  modalKey: 'AuthorizeIntegrationModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <AuthorizeIntegrationModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
