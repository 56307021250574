export default {
  default: {
    adjoiningSuburbs: 'Adjoining Suburbs',
    comparableTypes: {
      marketAll: 'All Market',
      marketCurrent: 'Current Market',
      marketRental: 'Current Rental',
      marketSold: 'Sold Market',
      organizationAll: 'All {global.organizationType.shortName.singular}',
      organizationCurrent: 'Current {global.organizationType.shortName.singular}',
      organizationSold: 'Sold {global.organizationType.shortName.singular}',
    },
    comparable: 'Comparable',
    listViewMode: 'List View',
    noNearbyResults: `None of the returned comparable properties are in adjoining suburbs.
    Check the "This Suburb" tab or adjust the search filters to see other results.`,
    noSuburbResults: `None of the returned comparable properties are in this suburb.
      Check the "Adjoining Suburb" tab or adjust the search filters to see other results.`,
    propertiesReturned: 'properties returned',
    thisSuburb: 'This Suburb',
    tileViewMode: 'Tile View',
  },
}
