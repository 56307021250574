import { useAuth0 } from '@auth0/auth0-react'

import { faShieldAlt, faUserCog } from '@fortawesome/pro-light-svg-icons'

import { TopBar } from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const LoggedInUser = () => {
  const { logout, user } = useAuth0()
  const { 'https://www.realhubapp.com/id': realbaseId, name, picture } = user || {}

  const {
    currentUser, hasOrganizationsWhereAdmin, isAdmin, isBrandUser,
  } = useCurrentUser()

  const { strings } = useLocalization(localizedStrings)

  return (
    <TopBar.LoggedInUser
      callbacks={{ logout: () => logout({ returnTo: window.location.origin }) }}
      imageUrl={picture}
      title={name}
      subtitle={`Realhub #${realbaseId}`}
    >
      {isAdmin && (
        <TopBar.DropdownLink
          href={`#/systemManager/users/${currentUser.id}/edit`}
          icon={faShieldAlt}
          text={strings.linkTextAccount || 'Account Settings'}
        />
      )}

      {hasOrganizationsWhereAdmin && (
        <TopBar.DropdownLink
          href="#/admin/organizations/edit"
          icon={faUserCog}
          text={strings.linkTextAdmin || 'Admin Settings'}
        />
      )}

      {/* story/ENG-29 This is a temporary solution whilst the Account Settings page for the brand user is blank. */}
      {/* This should be reversed once the has been developed. */}
      {!isAdmin && !isBrandUser && (
        <TopBar.DropdownLink
          href={`#/admin/users/${currentUser.id}/edit`}
          icon={faShieldAlt}
          text={strings.linkTextAccount || 'Account Settings'}
        />
      )}
    </TopBar.LoggedInUser>
  )
}

export default LoggedInUser
