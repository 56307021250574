import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box,
  Button,
  Checkbox,
  DynamicInput,
  FormField,
  ListItem,
  ModalContext,
  SectionDivider,
  SidebarModal,
} from '@campaignhub/suit-theme'

import { deepSetObject, digObject } from '@campaignhub/javascript-utils'

import EntitySelector from '@components/EntitySelector'
import FilterUsersContactsAndTeams from '@components/FilterUsersContactsAndTeams'
import ModalIntegrationPlatform from '@components/ModalComponents/ModalIntegrationPlatform'
import ModalUsers from '@components/ModalComponents/ModalUsers'

import useCurrentUser from '@hooks/useCurrentUser'
import useIntegration, { useIntegrationForm } from '@hooks/useIntegration'
import useIntegrationPlatform from '@hooks/useIntegrationPlatform'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import localizedStrings from './localizedStrings'

const confirmDelete = (params) => {
  const {
    callbacks, deleteFn, integrationPlatform, strings, trackEvent, trackIntegrationEvent,
  } = params

  const { closeModal, deleteIntegration } = callbacks

  const deleteIntegrationPayload = {
    callbacks: {
      action: deleteFn,
      afterAction: closeModal,
    },
    toastText: strings.toast?.integrationDeleted || 'Integration Deleted Successfully',
  }

  swal
    .fire({
      title: strings.swal?.title || 'Delete Integration?',
      text: strings.swal?.text || 'Are you sure? ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteIntegration(deleteIntegrationPayload).then(() => {
          trackIntegrationEvent({ action: 'deleteEvent', integrationPlatformKey: integrationPlatform.key, trackEvent })
        })
      }
    })
}

const createOrUpdate = (params) => {
  const {
    entityState, callbacks, integrationPlatform, strings, trackEvent,
  } = params

  const {
    closeModal, createIntegration, updateIntegration, createOrUpdateFn, createOrEditIntegration, trackIntegrationEvent,
  } = callbacks || {}

  const toastText = !!entityState.id
    ? strings.toast?.integrationUpdated || 'Integration Updated Successfully'
    : strings.toast?.integrationCreated || 'Integration Created Successfully'

  const integrationPayload = {
    callbacks: {
      action: createOrUpdateFn,
      afterAction: ({ response: { data } }) => {
        if (entityState.id){
          closeModal()
          trackIntegrationEvent({ action: 'updateEvent', integrationPlatformKey: integrationPlatform.key, trackEvent })
        } else {
          createOrEditIntegration({ integration: data?.entity })
          trackIntegrationEvent({ action: 'createEvent', integrationPlatformKey: integrationPlatform.key, trackEvent })
        }
      },
    },
    entityParams: entityState,
    toastText,
  }

  return entityState.id ? updateIntegration(integrationPayload) : createIntegration(integrationPayload)
}

const CreateOrEditIntegrationModal = (props) => {
  const {
    callbacks, modalKey, showModal, trackEvent,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const {
    callbacks: { createOrUpdateIntegration: createOrUpdateFn, deleteIntegration: deleteFn },
    integration,
  } = modalPayload

  const { isSelectedOrganizationAdmin } = useOrganizationSelector()
  const { currentUser, isAdmin } = useCurrentUser()

  const {
    callbacks: { createOrEditIntegration, trackIntegrationEvent }, creating, integrationPlatform, integrationScope,
  } = useIntegration(integration)

  const {
    dataFields, optionalDataFields, requiredDataFields, supportedFeatures, supportedFeaturesCount,
  } = useIntegrationPlatform(integrationPlatform)

  const formPayload = useIntegrationForm(
    { ...integration, integration_scope: integrationScope },
    {
      customOptionFields: supportedFeatures,
      customOptionalDataFields: optionalDataFields,
      customRequiredDataFields: requiredDataFields,
    },
  )

  const {
    entityState,
    entityState: {
      data, options, subject_id, owner_id,
    },
    handlers,
    saveEnabled,
    setEntityState,
  } = formPayload

  useEffect(() => {
    if (!isSelectedOrganizationAdmin && !!currentUser.id && !isAdmin){
      setEntityState({ subject_id: currentUser.id, subject_type: 'User' })
    }
  }, [isSelectedOrganizationAdmin, currentUser.id])

  const {
    callbacks: { formatCount },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={entityState.id ? strings.titleEdit || 'Edit' : strings.titleCreate || 'Create'}
        titleSecondLine={strings.titleSecondLine || 'Integration'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <ModalIntegrationPlatform integrationPlatform={integrationPlatform} />

          <SectionDivider boxProps={{ marginTop: 'medium', flexShrink: 0 }}>
            {strings.options || 'OPTIONS'}
          </SectionDivider>

          {integrationScope === 'user' && (
            <>
              {(isSelectedOrganizationAdmin || isAdmin) && (
                <FormField direction="column" label={`* ${strings.labelUser || 'User'}`}>
                  <FilterUsersContactsAndTeams
                    callbacks={{
                      selectItem: selected => setEntityState({ subject_id: selected.id, subject_type: 'User' }),
                    }}
                    selectedUserIds={[subject_id]}
                  />
                </FormField>
              )}

              <ModalUsers
                callbacks={{ deleteUser: () => setEntityState({ subject_id: null, subject_type: '' }) }}
                disableDeleteForUserIds={isSelectedOrganizationAdmin ? [] : [currentUser.id]}
                userIds={[subject_id]}
              />
            </>
          )}

          {integrationScope === 'organization'
            && isAdmin && (
              <EntitySelector
                boxProps={{ marginBottom: 'large' }}
                callbacks={{
                  selectItem: selected => setEntityState({ owner_id: selected?.id, owner_type: 'Organization' }),
                }}
                entityKey="organizations"
                selectedItemId={owner_id}
              />
          )}

          {dataFields.map((field) => {
            const {
              key, label, option_values, required,
            } = field

            return (
              <FormField
                boxProps={{ marginTop: 'large' }}
                direction="column"
                key={key}
                label={required ? `* ${label}` : label}
              >
                <DynamicInput
                  currentValue={data[key] || ''}
                  inputComponent={option_values ? 'select' : 'input'}
                  name={`data.${key}`}
                  required={required}
                  values={option_values && option_values.map(item => ({ key: item.key, label: item.value }))}
                  {...handlers}
                />
              </FormField>
            )
          })}

          {!!entityState.id && !!supportedFeaturesCount && (
          <SidebarModal.ExpandableSection
            defaultOpen
            label={strings.labelSupported || 'Supported Feature List'}
            style={{ marginTop: 16 }}
            title={formatCount('featureCount', { count: supportedFeaturesCount })}
          >
            {supportedFeatures.map((supportedFeature) => {
              const { key, label } = supportedFeature
              const featureEnabled = digObject(options, key, false)

              return (
                <ListItem.WithCheckbox
                  boxProps={{ border: 'none' }}
                  checkbox={(
                    <Checkbox
                      checked={featureEnabled}
                      onClick={() => setEntityState({ options: deepSetObject(options, key, !featureEnabled) })}
                    />
                      )}
                  onClick={() => setEntityState({ options: deepSetObject(options, key, !featureEnabled) })}
                  key={key}
                >
                  <Box color="bodyFontLightColor" fontSize="small">
                    {label}
                  </Box>
                </ListItem.WithCheckbox>
              )
            })}
          </SidebarModal.ExpandableSection>
          )}

          {!!entityState.id
              && (
              <FormField direction="column" label={strings.labelMoreOptions || 'More Options'} marginTop="large">
                <Button
                  buttonStyle="secondaryUtility"
                  onClick={() => confirmDelete({
                    callbacks, deleteFn, strings, trackEvent, integrationPlatform, trackIntegrationEvent,
                  })}
                  icon={<FontAwesomeIcon icon={faTrashAlt} />}
                  size="medium"
                  width="100%"
                >
                  {strings.buttonDeleteIntegration || 'Delete Integration'}
                </Button>
              </FormField>
              )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          onClick={() => createOrUpdate({
            callbacks: {
              createOrUpdateFn, createOrEditIntegration, trackIntegrationEvent, ...callbacks,
            },
            entityState,
            strings,
            trackEvent,
            integrationPlatform,
          })}
          size="large"
        >
          {strings.buttonSave || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrEditIntegrationModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
  trackEvent: PropTypes.func,
}

CreateOrEditIntegrationModal.defaultProps = {
  modalKey: 'CreateOrEditIntegrationModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditIntegrationModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
