import { useContext } from 'react'
import cloneDeep from 'lodash/cloneDeep'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { digObject, launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as integrationActions from '@redux/modules/integration'

import defaultFormState, { requiredFields as defaultRequiredFields } from '@models/integration'

import PageContext from '@contexts/pageContext'

import { TrackEventParams } from '@hooks/useIntercom'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { IntegrationModel, IntegrationRequestOptions } from '@models/types'
import { EntitiesState } from '@redux/entities'

type CreateOrUpdateIntegrationParams = {
  dispatch: AppDispatch,
  integration: IntegrationModel,
  integrationParams: Partial<IntegrationModel>,
  requestOptions?: IntegrationRequestOptions,
}

const createOrUpdateIntegration = (params: CreateOrUpdateIntegrationParams) => {
  const {
    dispatch, integration, integrationParams, requestOptions,
  } = params

  const { createOrUpdateIntegration: createOrUpdateFn } = integrationActions

  const { data, options } = integrationParams
  const newIntegration = {
    ...integrationParams,
  }

  if (data){
    const integrationData = integration.data || {}
    newIntegration.data = JSON.stringify({
      ...cloneDeep(integrationData),
      ...data,
    })
  }

  if (options){
    const integrationOptions = integration.options || {}
    newIntegration.options = JSON.stringify({
      ...cloneDeep(integrationOptions),
      ...options,
    })
  }

  return dispatch(createOrUpdateFn(newIntegration, requestOptions))
}

type DeleteIntegrationParams = {
  dispatch: AppDispatch,
  integration: DeleteParams<IntegrationModel>,
}

const deleteIntegration = (params: DeleteIntegrationParams) => {
  const { dispatch, integration } = params
  const { deleteIntegration: deleteFn } = integrationActions

  return dispatch(deleteFn(integration))
}

type GetIntegrationOwnerParams = {
  entities: EntitiesState,
  integration: IntegrationModel,
  integrationScope: string,
}

const getIntegrationOwner = (params: GetIntegrationOwnerParams) => {
  const { integrationScope, integration, entities } = params
  const { owner_id, subject_id } = integration
  const { users, organizations } = entities

  if (integrationScope === 'user'){
    return users[subject_id] || {}
  }

  return organizations[owner_id] || {}
}

type CustomFormOptions = {
  customOptionalDataFields?: Record<string, string>[],
  customOptionFields?: Record<string, string>[],
  customRequiredDataFields?: UseFormOptions['requiredFields'],
}

export function useIntegrationForm(
  integration: Partial<IntegrationModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const {
    customOptionalDataFields = [], customRequiredDataFields = [], customOptionFields = [],
  } = options || {}

  const mergedCustomDataFields = [...customOptionalDataFields, ...customRequiredDataFields]

  const integrationFormData = mergedCustomDataFields.reduce((customData, field) => {
    customData[field.key] = ''
    return customData
  }, {})

  const integrationFormOptions = customOptionFields.reduce((customOptions, field) => {
    customOptions[field.key] = false
    return customOptions
  }, {})

  const formState = {
    ...defaultFormState,
    data: integrationFormData,
    options: integrationFormOptions,
  }

  const requiredFields = [
    ...defaultRequiredFields,
    ...customRequiredDataFields.map(field => ({ key: `data.${field.key}` })),
  ]

  const integrationForm = useForm(formState, { entity: integration, requiredFields, validateOn: 'change' }, [
    integration.id,
  ])

  return integrationForm
}

export const useRelations = (integration: Partial<IntegrationModel> = {}) => {
  const { integration_platform_id } = integration || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const { integrationPlatforms } = entities

  const integrationPlatform = integration_platform_id ? integrationPlatforms[integration_platform_id] || {} : {}

  return {
    integrationPlatform,
  }
}

type EventConfig = {
  name: string,
  payload: Record<string, any>,
};

type IntegrationEventMappings = {
  [key: string]: {
    authorizeEvent: EventConfig,
    createEvent: EventConfig,
    deleteEvent: EventConfig,
    updateEvent: EventConfig,
  },
};

// TODO: All future integrations the should be tracked by Intercom, should be added here
const integrationEventMappings: IntegrationEventMappings = {
  real_time_agent: {
    authorizeEvent: { name: 'RTA Integration', payload: { integrated: true } },
    createEvent: { name: 'RTA Integration', payload: { created: true } },
    deleteEvent: { name: 'RTA Integration', payload: { integrated: false } },
    updateEvent: { name: 'RTA Integration', payload: { updated: true } },
  },
}

type TrackEventFunction = (params: TrackEventParams) => void;
type TrackIntegrationEventParams = {
  action: 'authorizeEvent' | 'createEvent' | 'deleteEvent'| 'updateEvent',
  integrationPlatformKey: string,
  trackEvent: TrackEventFunction,
};

function useIntegration(initEntity: Partial<IntegrationModel> = {}) {
  const { entity: integration }: { entity: IntegrationModel} = useLatestEntity(initEntity, 'integrations')

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { creating, loading } = useSelector(reduxState => reduxState.integrations)
  const entities = useSelector(reduxState => reduxState.entities)

  const { integrationPlatform } = useRelations(integration)

  const integrationScope = digObject(integrationPlatform, 'data.integration_scope')

  const integrationOwner = getIntegrationOwner({ entities, integration, integrationScope }) || {}
  const ownerTitle = integrationScope === 'user' ? integrationOwner.full_name : integrationOwner.title

  const trackIntegrationEvent = (params: TrackIntegrationEventParams) => {
    const { action, integrationPlatformKey, trackEvent } = params
    const eventConfig = integrationEventMappings[integrationPlatformKey]?.[action]

    if (eventConfig && typeof trackEvent === 'function'){
      trackEvent({ eventName: eventConfig.name, eventPayload: eventConfig.payload })
    }
  }

  return {
    callbacks: {
      authorizeIntegration: () => launchModal({
        callbacks,
        modalKey: 'AuthorizeIntegrationModal',
        payload: {
          integration,
          integrationPlatform,
        },
      }),

      createOrEditIntegration: (customPayload: {}) => launchModal({
        callbacks,
        modalKey: 'CreateOrEditIntegrationModal',
        payload: {
          callbacks: {
            createOrUpdateIntegration: (
              integrationParams: Partial<IntegrationModel>,
              entityOptions?: IntegrationRequestOptions,
            ) => (
              createOrUpdateIntegration({
                dispatch, integration, integrationParams, requestOptions: entityOptions,
              })
            ),
            deleteIntegration: () => deleteIntegration({ dispatch, integration }),
          },
          integration,
          integrationPlatform,
          ...customPayload,
        },
      }),
      trackIntegrationEvent,
    },
    creating,
    integration,
    integrationOwner,
    integrationPlatform,
    integrationScope,
    loading,
    ownerTitle,
  }
}

export default useIntegration
