import {
  CartesianGrid,
  Legend,
  Area,
  AreaChart as Chart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import CustomLegend from '@components/charts/Legend'
import CustomTooltip from '@components/charts/Tooltip'

import usePrintMode from '@hooks/usePrintMode'
import { AreaChartProps, isPrintMode } from '../../hooks/useChartHooks'

const AreaChart = (props: AreaChartProps) => {
  const {
    chartOptions: {
      barGap = 10,
      chartHeight,
      disableGrid,
      disableLegend,
      includeXAxis = true,
      layout,
      primaryColor,
    },
    dataPayload: { dataArray } = {},
    customClassName,
    height = 300,
    width = '100%',
  } = props

  const isPrinting = usePrintMode()

  return (
    <ResponsiveContainer width={width} height={height} className={customClassName}>
      <Chart
        barGap={barGap}
        data={dataArray}
        height={chartHeight}
        layout={layout}
      >
        {!disableGrid && (
          <CartesianGrid stroke="rgba(0, 0, 0, 0.1)" strokeDasharray="3 3" />
        )}

        <defs>
          <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={primaryColor} stopOpacity={0.8} />
            <stop offset="95%" stopColor={primaryColor} stopOpacity={0.1} />
          </linearGradient>
        </defs>

        <Area
          type="monotone"
          dataKey="value"
          stroke={primaryColor}
          fillOpacity={1}
          fill="url(#colorValue)"
          isAnimationActive={!isPrinting}
        />

        {!includeXAxis
          && (
            <>
              <XAxis hide type={layout === 'vertical' ? 'number' : 'category'} />
              <YAxis hide dataKey="name" type={layout === 'vertical' ? 'category' : 'number'} />
            </>
          )}

        {includeXAxis && (
          <XAxis
            axisLine={false}
            padding={{ left: 60, right: 60 }}
            dataKey="name"
            fontSize={10}
            lineHeight={10}
            tickLine={false}
          />
        ) }

        <YAxis dataKey="value" type={layout === 'vertical' ? 'category' : 'number'} />

        <Tooltip content={<CustomTooltip dataKeys={['value']} />} cursor={{ fill: 'none' }} />

        {!disableLegend && <Legend content={<CustomLegend />} verticalAlign="bottom" />}
      </Chart>
    </ResponsiveContainer>
  )
}

export default AreaChart
