import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import { useSetState, useThunkDispatch } from '@campaignhub/react-hooks'

import { digObject, sortArrayBy } from '@campaignhub/javascript-utils'

import { useRelations as useProjectRelations } from '@hooks/useProject'
import useAuthorizeIntegration from '@hooks/useAuthorizeIntegration'
import useCurrentUser from '@hooks/useCurrentUser'
import useIntegrations from '@hooks/useIntegrations'

import * as comparableActions from '@redux/modules/comparable'
import * as projectActions from '@redux/modules/project'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

import buildFilters from '../utils/buildFilters'
import defaultFilters from '../utils/defaultFilters'

const mappedFilterKeys = {
  date: 'date.sold',
  distance: 'distance',
  price: 'price.price',
}

export const getFiltersforIntegrationPlatform = (integrationPlatform) => {
  const platformFilters = digObject(integrationPlatform, 'data.import_comparables_options.browse_filters', [])
  const mappedFilters = platformFilters.map(filter => defaultFilters[filter])

  return mappedFilters
}

const combinedSearch = (externalPlatformKey, string, dispatch, setState) => {
  const { combinedSearch: combinedSearchFn } = comparableActions

  // Reset state
  setState({
    combinedSearchResult: [],
    errors: [],
    searchComplete: false,
    searching: true,
  })

  return dispatch(combinedSearchFn(externalPlatformKey, { string })).then((response) => {
    const { data, errors, success } = response

    setState({
      combinedSearchResult: success ? data : [],
      errors: !success ? errors : [],
      resultsCount: success ? data.length : 0,
      searchComplete: true,
      searchedString: string,
      searching: false,
    })

    return response
  })
}

const mapFiltersArrayToObject = (filtersArray) => {
  const filters = filtersArray.reduce((acc, filter) => {
    acc[filter.id] = filter
    return acc
  }, {})

  return filters
}

const fetchFromExternalPlatform = (state, externalId, organizationId, projectAddress, dispatch, setState) => {
  const { fetchFromExternalPlatform: fetchFromExternalPlatformFn } = comparableActions
  const { browseComparableType, filtersArray, selectedIntegrationPlatformKey } = state

  const filters = mapFiltersArrayToObject(filtersArray)
  const browseFilters = buildFilters(filters)

  setState({ searching: true })

  const comparableTypes = {
    market_all: 'all',
    market_current: 'listed',
    market_rental: 'rental',
    market_sold: 'sold',
  }
  const comparableType = comparableTypes[browseComparableType]

  const payload = {
    externalId,
    comparableType,
    organizationId,
    projectAddress,
  }

  return dispatch(fetchFromExternalPlatformFn(selectedIntegrationPlatformKey, payload, browseFilters)).then(
    (response) => {
      setState({ searching: false })

      const { success, data } = response
      if (success){
        setState({
          browseComplete: true,
          browseResults: data,
          resultsCount: data.length,
        })
      }

      return response
    },
  )
}

const fetchProjects = (state, dispatch, setState) => {
  const { searchProjects } = comparableActions
  const { browseComparableType, filtersArray } = state

  const comparableTypes = {
    organization_all: 'all',
    organization_current: 'listed',
    organization_sold: 'sold',
  }
  const comparableType = comparableTypes[browseComparableType]

  const filters = mapFiltersArrayToObject(filtersArray)

  const browseFilters = {
    ...buildFilters(filters),
    comparable_type: comparableType,
  }

  setState({ searching: true })

  return dispatch(searchProjects(browseFilters)).then((response) => {
    setState({ searching: false })

    const { success, data } = response
    if (success){
      setState({
        browseComplete: true,
        browseResults: data.entities,
        resultsCount: data.entities.length,
      })
    }

    return response
  })
}

const performSubmit = (externalId, organizationId, projectAddress, state, dispatch, setState) => {
  const {
    action, browseComparableType, searchString, selectedIntegrationPlatformKey,
  } = state

  if (action === 'search'){
    return combinedSearch(selectedIntegrationPlatformKey, searchString, dispatch, setState)
  }

  if (action === 'browse'){
    if (browseComparableType.includes('market')){
      return fetchFromExternalPlatform(state, externalId, organizationId, projectAddress, dispatch, setState)
    }
    return fetchProjects(state, dispatch, setState)
  }

  return null
}

const importComparable = (externalPlatformKey, params, payload, dispatch) => {
  const { importComparable: importComparableFn } = comparableActions
  const { id, distance } = params

  const updatedPayload = {
    ...payload,
    externalId: id,
    distance,
  }

  const requestOptions = {
    ...defaultRequestOptions.comparable,
    ...defaultRequestOptions.image,
  }

  return dispatch(importComparableFn(externalPlatformKey, updatedPayload, requestOptions))
}

const createComparableFromProject = (params, comparableOwnerId, dispatch) => {
  const { createComparableFromProject: createComparableFromProjectFn } = comparableActions
  const { id } = params

  const requestOptions = {
    ...defaultRequestOptions.comparable,
    ...defaultRequestOptions.image,
  }

  return dispatch(createComparableFromProjectFn(id, comparableOwnerId, requestOptions))
}

const updateProjectExternalId = (externalPlatformKey, project, externalId, dispatch, setState) => {
  const { updateProject: updateProjectFn } = projectActions

  setState({ searching: true })

  const { data } = project
  const { external_ids } = data

  // Need to merge nested levels of data to prevent overwrite
  const updatedExternalIds = {
    ...external_ids,
    [externalPlatformKey]: externalId,
  }

  const updatedData = {
    ...data,
    external_ids: updatedExternalIds,
  }

  const updatedProject = {
    id: project.id,
    data: JSON.stringify(updatedData),
  }

  return dispatch(updateProjectFn(updatedProject)).then((response) => {
    setState({ searching: false })
    return response
  })
}

const fetchPropertyMatchSuggestions = (externalPlatformKey, address, organizationId, dispatch, setState) => {
  const { searchInExternalPlatform: searchInExternalPlatformFn } = comparableActions

  if (externalPlatformKey){
    setState({
      errors: [],
      propertyMatchSuggestions: [],
      searching: true,
    })

    dispatch(
      searchInExternalPlatformFn(externalPlatformKey, { string: address, organization_id: organizationId }),
    ).then((result) => {
      const { data, errors, success } = result

      setState({
        errors: !success ? errors : [],
        propertyMatchSuggestions: success ? data : [],
        searching: false,
      })
    })
  }
}

const changeFilter = (key, name, value, state, setState) => {
  const { filtersArray } = state

  const newFilters = [...filtersArray]
  newFilters.forEach((filter, index) => {
    if (filter.id === key) newFilters[index][name] = value
  })

  setState({
    filtersArray: newFilters,
    browseResults: [],
    resultsCount: 0,
  })
}

const sortFilteredBrowseResults = (filtersArray, filteredBrowseResults) => {
  if (!filtersArray.length) return filteredBrowseResults

  const filter = filtersArray.find(f => f.id === 'sort')
  const filterValue = filter?.value || ''
  const sortDirection = filterValue.startsWith('-') ? 'desc' : 'asc'
  const selectedFilterKey = filterValue.replace('-', '')

  const key = mappedFilterKeys[selectedFilterKey] || 'date.sold'

  return sortArrayBy(filteredBrowseResults, sortDirection, browseResult => digObject(browseResult, key, ''))
}

const defaultState = {
  action: 'default',
  blankFilters: [],
  browseAddressFilter: '',
  browseComparableType: 'market_all',
  browseComplete: false,
  browseResults: [],
  cloneFromProjectId: null,
  combinedSearchResult: [],
  errors: [],
  filterComparablesBySuburbKey: 'this_suburb',
  filtersArray: [],
  propertyMatchId: '',
  propertyMatchSuggestions: [],
  resultsCount: null,
  searchComplete: false,
  searchString: '',
  searchedString: '',
  searching: false,
  selectedIntegrationPlatformKey: '',
  showFilters: false,
}

const useFindComparables = (projectId) => {
  const [state, setState] = useSetState(defaultState)
  const {
    action,
    blankFilters,
    browseAddressFilter,
    browseComparableType,
    browseComplete,
    browseResults,
    cloneFromProjectId,
    combinedSearchResult,
    errors,
    filterComparablesBySuburbKey,
    filtersArray,
    propertyMatchId,
    propertyMatchSuggestions,
    resultsCount,
    searchComplete,
    searchString,
    searchedString,
    searching,
    selectedIntegrationPlatformKey,
    showFilters,
  } = state

  const { countries, projects } = useSelector(reduxState => reduxState.entities)
  const project = projects[projectId] || {}
  const organizationId = project.organization_id
  const projectRelations = useProjectRelations({ id: projectId })
  const { address: projectAddress } = projectRelations

  const currentUserPayload = useCurrentUser()
  const { currentUser } = currentUserPayload

  const { filteredPlatforms } = useIntegrations({
    featureKeys: ['import_comparables'],
    filters: {
      subject_id: currentUser.id,
      subject_type: 'User',
    },
  })

  const selectedIntegrationPlatform = filteredPlatforms.find(platform => platform.key === selectedIntegrationPlatformKey) || {}

  const dispatch = useThunkDispatch()

  // Split combinedSearchResult into organizationResults and marketResults
  const splitResult = combinedSearchResult.reduce(
    (acc, comparable) => {
      if (comparable && comparable.service_provider === 'engage'){
        acc.organizationResults.push(comparable)
      } else {
        acc.marketResults.push(comparable)
      }

      return acc
    },
    { organizationResults: [], marketResults: [] },
  )

  const organizationResults = digObject(splitResult, 'organizationResults', [])
  const marketResults = digObject(splitResult, 'marketResults', [])

  useEffect(() => {
    if (searchComplete) setState({ searchComplete: false })
  }, [searchString])

  useEffect(() => {
    setState({
      browseAddressFilter: '',
      browseComplete: false,
      browseResults: [],
      marketResults: [],
      organizationResults: [],
      searchComplete: false,
      searchString: '',
      searchedString: '',
    })
  }, [browseComparableType, selectedIntegrationPlatformKey])

  // Authorize Integration Payload to handle REINZ External Authorization
  const useAuthorizeIntegrationPayload = useAuthorizeIntegration({ filteredPlatforms, selectedIntegrationPlatformKey })
  const {
    integrationExpired,
    oauthProviderKey,
  } = useAuthorizeIntegrationPayload || {}

  // Set the external ID of the project if there isn't one
  const externalId = digObject(project, `data.external_ids.${selectedIntegrationPlatformKey}`)
  useEffect(() => {
    if (!externalId && action === 'browse' && organizationId && projectAddress){
      if (oauthProviderKey && integrationExpired){
        return
      }

      fetchPropertyMatchSuggestions(
        selectedIntegrationPlatformKey,
        projectAddress.full_address,
        organizationId,
        dispatch,
        setState,
      )
    }
  }, [action, externalId, selectedIntegrationPlatformKey])

  // Autoselect integration platform if there is only 1
  useEffect(() => {
    if (filteredPlatforms.length === 1){
      setState({ selectedIntegrationPlatformKey: filteredPlatforms[0].key })
    }
  }, [])

  // Reset button when filters change
  useEffect(() => {
    if (browseComplete) setState({ browseComplete: false })
  }, [JSON.stringify(filtersArray)])

  const country = countries[projectAddress?.country_id] || {}

  // Set pricefinder as the default integration platform for Australia
  useEffect(() => {
    if (country?.code === 'AU') setState({ selectedIntegrationPlatformKey: 'pricefinder' })
  }, [country])

  // Reset browseComplete and searchComplete when action changes
  useEffect(() => {
    setState({ browseComplete: false, searchComplete: false })
  }, [action])

  const filters = getFiltersforIntegrationPlatform(selectedIntegrationPlatform)

  // Save a copy of blank filters so we can reset
  useEffect(() => {
    setState({ blankFilters: cloneDeep(filters) })
  }, [selectedIntegrationPlatformKey])

  useEffect(() => {
    setState({ filtersArray: filters })
  }, [selectedIntegrationPlatformKey, filters.length])

  // Automatically set filters for latitude and longitude
  useEffect(() => {
    if (filtersArray.length){
      changeFilter('latitude', 'value', projectAddress.latitude, state, setState)
      changeFilter('longitude', 'value', projectAddress.longitude, state, setState)
    }
  }, [projectAddress.latitude, projectAddress.longitude, filtersArray.length])

  const activeFiltersCount = Object.values(filters).filter(
    f => (f.value && f.id !== 'sort' && f.id !== 'radius') || (f.id === 'radius' && f.value !== '2'),
  ).length

  const projectSuburb = projectAddress?.region_name || ''

  const filterBySuburb = (comparables, projectSuburbArg, matchSuburb) => comparables.filter((comparable) => {
    const suburb = comparable.address?.suburb_name?.toLowerCase() || ''
    if (!projectSuburbArg) return !matchSuburb
    const suburbMatch = suburb === projectSuburbArg.toLowerCase()
    return matchSuburb ? suburbMatch : !suburbMatch
  })

  const sortedFilteredBrowseResults = useMemo(() => {
    let results = [...browseResults]

    const shouldReturnSuburbResults = filterComparablesBySuburbKey === 'this_suburb'
    results = filterBySuburb(results, projectSuburb, shouldReturnSuburbResults)

    if (browseAddressFilter){
      results = results.filter((result) => {
        const { address, suburb_name } = result.address
        const stringToSearch = `${address}, ${suburb_name}`.toLowerCase()
        return stringToSearch.includes(browseAddressFilter.toLowerCase())
      })
    }

    results = sortFilteredBrowseResults(filtersArray, results)

    return results
  }, [browseResults, filterComparablesBySuburbKey, browseAddressFilter, filtersArray])

  const payload = {
    organizationId,
    projectId,
  }

  const hasPropertyMatchSuggestions = !!propertyMatchSuggestions.length

  return {
    action,
    activeFiltersCount,
    browseAddressFilter,
    browseComparableType,
    browseComplete,
    callbacks: {
      changeFilter: (key, name, value) => changeFilter(key, name, value, state, setState),
      combinedSearch: (externalPlatformKey, string) => combinedSearch(externalPlatformKey, string, dispatch, setState),
      createComparableFromProject: params => createComparableFromProject(params, projectId, dispatch),
      fetchProjects: () => fetchProjects(state, dispatch, setState),
      importComparable: params => importComparable(selectedIntegrationPlatformKey, params, payload, dispatch),
      resetFilters: () => setState({ filtersArray: cloneDeep(blankFilters) }),
      setFilterComparablesBySuburbKey: value => setState({ filterComparablesBySuburbKey: value }),
      setModalState: values => setState(values),
      submitAction: () => performSubmit(externalId, organizationId, projectAddress, state, dispatch, setState),
      updateProjectExternalId: externalId => updateProjectExternalId(selectedIntegrationPlatformKey, project, externalId, dispatch, setState),
    },
    cloneFromProjectId,
    combinedSearchResult,
    errors,
    externalId,
    filterComparablesBySuburbKey,
    filteredBrowseResults: sortedFilteredBrowseResults,
    filteredPlatforms,
    filtersArray,
    hasMarketResults: !!marketResults?.length,
    hasOrganizationResults: !!organizationResults?.length,
    hasPropertyMatchSuggestions,
    isMatchedWithDataProvider: !!externalId,
    marketResults,
    organizationResults,
    projectId,
    propertyMatchId,
    propertyMatchSuggestions,
    resultsCount,
    searchComplete,
    searchString,
    searchedString,
    searching,
    selectedIntegrationPlatform,
    selectedIntegrationPlatformKey,
    showFilters,
    useAuthorizeIntegrationPayload,
  }
}

export default useFindComparables
