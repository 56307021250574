import { digObject } from '@campaignhub/javascript-utils'

import buildMinMaxValues from './buildMinMaxValues'

export function buildBrowseFilters(filters){
  const {
    area,
    bathrooms,
    bedrooms,
    carparks,
    price,
  } = filters

  const dateEnd = digObject(filters, 'end_date.value', '')
  const dateStart = digObject(filters, 'start_date.value', '')
  const latitudeValue = digObject(filters, 'latitude.value', '')
  const longitudeValue = digObject(filters, 'longitude.value', '')
  const propertyTypeValue = digObject(filters, 'property_type.value', '')
  const radiusValue = digObject(filters, 'radius.value', '2')
  const sortValue = digObject(filters, 'sort.value', 'distance')

  const basicFilters = {
    date_end: dateEnd,
    date_start: dateStart,
    limit: 25,
    radius: radiusValue,
    sort: sortValue,
  }

  const advancedFilters = {}

  if (propertyTypeValue){
    basicFilters.property_type = propertyTypeValue
  }

  if (!!longitudeValue && !!latitudeValue){
    advancedFilters.latitude = latitudeValue
    advancedFilters.longitude = longitudeValue
  }

  const areaValues = buildMinMaxValues('area', area)
  const bedroomsValues = buildMinMaxValues('beds', bedrooms)
  const bathroomsValues = buildMinMaxValues('baths', bathrooms)
  const carparksValues = buildMinMaxValues('car_parks', carparks)
  const priceValues = buildMinMaxValues('price', price)

  return {
    ...basicFilters,
    ...areaValues,
    ...bedroomsValues,
    ...bathroomsValues,
    ...carparksValues,
    ...priceValues,
    ...advancedFilters,
  }
}

export default buildBrowseFilters
