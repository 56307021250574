import { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faGlobe } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader, Tools,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import OrganizationListItem from '@components/OrganizationListItem'
import PageFilterModule from '@components/PageFilterModule'

import useCurrentUser from '@hooks/useCurrentUser'
import useIntercom from '@hooks/useIntercom'
import useMixpanel from '@hooks/useMixpanel'
import useOrganizations from '@hooks/useOrganizations'

import OrganizationBlankState from '../OrganizationBlankState'

const buildPageFilterFields = (isAdmin) => {
  const boxProps = {
    marginBottom: 'large',
  }

  const style = {
    marginBottom: 16,
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: 'Filter by Title, ID or Realhub ID',
    },
    {
      callbacks: {
        shouldRender: () => isAdmin,
      },
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        entityKey: 'brands',
      },
      key: 'brand_id',
    },
    {
      entityKey: 'onboarding',
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'Please Select',
        inputComponent: 'select',
        style,
      },
      key: 'onboarding',
      label: 'Onboarding',
      values: [
        { key: 'true', label: 'Yes' },
        { key: 'false', label: 'No' },
      ],
    },
    {
      entityKey: 'limit',
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: '100',
        inputComponent: 'select',
        style,
      },
      key: 'limit',
      label: 'Limit',
      values: [
        { key: 150, label: '150' },
        { key: 200, label: '200' },
      ],
    },
  ]
}

const PageContent = (props) => {
  const { pageFilters } = props

  const useOrganizationsPayload = useOrganizations({ filters: pageFilters, performHttpRequests: true })
  const {
    callbacks: { loadMore },
    canLoadMore,
    filteredOrganizations,
    hasOrganizations,
    loading,
  } = useOrganizationsPayload

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      resetFilters, showCreateOrganizationModal, showImportOrganizationsModal, updateFilters,
    },
  } = pageContext

  const { isAdmin, isBrandUser } = useCurrentUser()

  useIntercom({ hideWidget: !isBrandUser })

  // Tracks the page load event
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()
  useEffect(() => {
    if (isBrandUser) dispatchMixpanelEvent('Organisation Load')
  }, [])

  return (
    <>
      <Helmet>
        <title>Organizations | Engage</title>
      </Helmet>

      <PageHeader
        actionContent={isAdmin && (
          <Button buttonStyle="primaryCreate" onClick={() => showCreateOrganizationModal()} size="medium">
            Create
          </Button>
        )}
        activeTabBarItemKey="all-organizations"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/',
            icon: faGlobe,
            key: 'all-organizations',
            title: 'Organizations',
          },
        ]}
        title="Organizations"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!hasOrganizations && !loading && <OrganizationBlankState />}

            {hasOrganizations && !loading && (
              <DashboardModule title="Organizations">
                <Box flexDirection="column">
                  <Box flexDirection="column">
                    {filteredOrganizations.map(organization => (
                      <OrganizationListItem key={organization.id} organization={organization} />
                    ))}
                  </Box>

                  <DashboardModule.LoadMoreFooter
                    callbacks={{ loadMore }}
                    canLoadMore={canLoadMore}
                    entityCount={filteredOrganizations.length}
                    loading={loading}
                  />
                </Box>
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            {isAdmin && (
            <Tools title="Tools">
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faFile} />}
                marginBottom="medium"
                onClick={() => showImportOrganizationsModal()}
                size="medium"
              >
                Import Organizations
              </Button>
            </Tools>
            )}
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields(isAdmin)}
              pageFilters={pageFilters}
              title="Filters"
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object.isRequired,
}

export default PageContent
